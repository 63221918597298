import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Masonry from '@mui/lab/Masonry';

import Database from '../../services/Database';
import Storage from '../../services/Storage';
import Article from '../article/Article';
import ArticleCard from './ArticleCard';
import Editor from '../../components/Editor';
import Viewer from '../../components/Viewer';


interface Props {
  edit: boolean;
}

export default function Articles({edit}: Props) {
  const [page, setPage] = useState<{name:string, html: any, articles:any}|undefined>();
  const location = useLocation();
  const navigate = useNavigate();
  const pageKey = location.pathname.split('/')[1];
  const articleKey = location.pathname.split('/')[2];
  
  useEffect(() => {
    Database.onValue(`/pages/${pageKey}`, (pageData:any) => setPage(pageData) );
  }, [pageKey]);

  const onClick = (key:string,) => {
    if (page?.articles[key].type === 'article') {
      navigate(key)
    }
    if (page?.articles[key].type === 'link') {
      window.open(page?.articles[key].link, "_blank");
    }
  }

  const onChange = (key:string, field:string, value:any) => {
    Database.update(`/pages/${pageKey}/articles/${key}`, {[field]: value});
  }

  const onPush = () => {
    Database.push(`/pages/${pageKey}/articles`, {name: 'Neue Karte', type:"card", sort: 1});
  }

  const onDelete = (key:string) => {
    Database.delete(`/pages/${pageKey}/articles/${key}`);
  }

  const onImagePush = async (key:string, event:any) => {
    const file = event.target.files[0];
    await Storage.uploadBytes(`/pages/${pageKey}/articles/${key}/image`, file);
    const url = await Storage.getDownloadURL(`/pages/${pageKey}/articles/${key}/image`);
    Database.update(`/pages/${pageKey}/articles/${key}`, { image: url });
  }

  const uploadImage = async (file: any) => {
    await Storage.uploadBytes(`/pages/${pageKey}/images/${file.name}`, file);
    return Storage.getDownloadURL(`/pages/${pageKey}/images/${file.name}`);
  }

  const onChangeHtml = (value: string) => {
    Database.update(`/pages/${pageKey}`, {html: value});
  }

  return articleKey ? (
    <Container disableGutters>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" onClick={() => navigate(`/${pageKey}`)} sx={{ cursor: 'pointer' }}>{page && page.name}</Link>
        <Typography color="text.primary">{page && page.articles[articleKey].name}</Typography>
      </Breadcrumbs>
      <Divider sx={{ mb:1 }} />
      <Article edit={edit} />
    </Container>
  ) : (
    <Container disableGutters>
      <Box display="flex" alignItems={'center'}>
        {page && page.name && (<Typography variant="h1" fontWeight={900} color="primary">{page.name}</Typography>)}
        <Box flexGrow={1} />
        {edit && <Button variant="contained" onClick={onPush}>+</Button>}
      </Box>
      {page && page.name && (<Divider sx={{ mb:2 }}/>)}
      <Box sx={{mb: 2}}>
        {page && !edit && <Viewer value={page.html} /> }
        {page && edit && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Editor</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Editor initialValue={page.html} uploadImage={uploadImage} onChange={onChangeHtml} />
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <Masonry columns={{ xs: 1, sm: 2, lg: 3 }} spacing={2}>
        {page && page.articles && Object.keys(page.articles)
        .sort((a,b) => (page.articles[a].sort || 1) - (page.articles[b].sort || 1) )
        .map((key) => (
          <ArticleCard 
            key={key}
            caption={page.articles[key].caption}
            edit={edit} 
            id={key}
            name={page.articles[key].name}
            image={page.articles[key].image}
            type={page.articles[key].type || 'card'}
            link={page.articles[key].link}
            sort={page.articles[key].sort || 1}
            onClick={onClick}
            onChange={onChange}
            onDelete={onDelete}
            onImagePush={onImagePush}
          />
        ))}
      </Masonry>
    </Container>
  );
}
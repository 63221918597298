import React, { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';

import Recipientlists from './recipientlists/Recipientlists';
import Recipients from './recipients/Recipients';
import local from './Mails.local';

export default function Newsletter() {
  const [tab, setTab] = useState<number>(0);

  return (
    <Container disableGutters>
      <Tabs
        value={tab}
        onChange={(event, value) => setTab(value)}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
      >
        <Tab label={local.newsletter} />
        <Tab label={local.recipientlists} />
        <Tab label={local.recipients} />
      </Tabs>
      <Divider sx={{ mb: 2 }} />
      <Box hidden={tab !== 0} textAlign="center">
        <CircularProgress  sx={{ mt: 10 }} />
      </Box>
      <Box hidden={tab !== 1}>
        <Recipientlists />
      </Box>
      <Box hidden={tab !== 2}>
        <Recipients />
      </Box>
    </Container>
  );
}
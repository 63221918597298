import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

interface Props {
  imageButton?: boolean
  accept: string
  hoverLabel?: string
  dropLabel?: string
  width?: string
  height?: string
  backgroundColor?: string
  imageUrl?: string
  imageStyle?: {
    width?: string
    height?: string
  }
  onChange: (file: any) => void
}

export default function FileUpload ({
  accept,
  imageButton = false,
  hoverLabel = 'Klicken oder ziehen, um Datei hochzuladen',
  dropLabel = 'Datei hier ablegen',
  width = '600px',
  height = '100px',
  backgroundColor = '#fff',
  imageUrl,
  imageStyle = {
    height: 'inherit',
  },
  onChange,
}: Props) {
  const [labelText, setLabelText] = useState<string>(hoverLabel);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isMouseOver, setIsMouseOver] = useState<boolean>(false);

  const stopDefaults = (e: React.DragEvent) => {
    e.stopPropagation()
    e.preventDefault()
  }
  const dragEvents = {
    onMouseEnter: () => {
      setIsMouseOver(true)
    },
    onMouseLeave: () => {
      setIsMouseOver(false)
    },
    onDragEnter: (e: React.DragEvent) => {
      stopDefaults(e)
      setIsDragOver(true)
      setLabelText(dropLabel)
    },
    onDragLeave: (e: React.DragEvent) => {
      stopDefaults(e)
      setIsDragOver(false)
      setLabelText(hoverLabel)
    },
    onDragOver: stopDefaults,
    onDrop: (e: React.DragEvent<HTMLElement>) => {
      stopDefaults(e)
      setLabelText(hoverLabel)
      setIsDragOver(false)
      if (e.dataTransfer.files[0]) {
        onChange(e.dataTransfer.files[0]);
      }
    },
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files && event.target.files[0]) {
      onChange(event.target.files[0]);
    }
  }

  return (
    <>
      <input onChange={handleChange} accept={accept} id="file-upload" type="file" style={{display: 'none'}}/>
      <Typography component="label" htmlFor="file-upload" {...dragEvents} sx={{
        cursor: 'pointer',
        textAlign: 'center',
        display: 'flex',
        '&:hover p,&:hover svg,& img': {
            opacity: 1,
        },
        '& p, svg': {
            opacity: 0.4,
        },
        '&:hover img': {
            opacity: 0.3,
        },
        ...(isDragOver && {
          '& img': {
            opacity: 0.3,
          },
          '& p, svg': {
            opacity: 1,
          },
        })
      }}>
        <Box width={width} height={height} bgcolor={backgroundColor} sx={{pointerEvents: 'none'}}>
          {imageButton && (
            <Box position="absolute" height={height} width={width}>
              <img alt="file upload" src={imageUrl} style={imageStyle}/>
            </Box>
          )}
          {(!imageButton || isDragOver || isMouseOver) && (
            <>
              <Box height={height} width={width} sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',
              }}>
                <CloudUploadIcon fontSize="large" />
                <Typography>{labelText}</Typography>
              </Box>
            </>
          )}
        </Box>
      </Typography>
    </>
  )
}

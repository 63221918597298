import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import local from './RecipientModal.local';

interface Props {
  title:string;
  id?:string;
  email?:string;
  name?:string;
  lists?:any;
  error?:string;
  open:boolean;
  recipientlists:any;
  onClose: () => void;
  onConfirm: (email:string, name:string, lists:any, id?:string) => void;
  onTyping: () => void;
}

export default function RecipientModal({
  id,
  title,
  email,
  name,
  lists,
  error,
  open,
  recipientlists,
  onConfirm,
  onClose,
  onTyping,
}: Props) {
  const [emailState, setEmailState] = useState<string>('');
  const [nameState, setNameState] = useState<string>('');
  const [listsState, setListsState] = useState<any>(lists);
  
  useEffect(() => setEmailState(email || ''), [email]);
  useEffect(() => setEmailState(name || ''), [name]);
  useEffect(() => setListsState(lists || {}), [lists]);
  
  const onConfirmClick = (event:any) => {
    event.preventDefault();
    onConfirm(emailState, nameState, listsState, id);
  }

  const onChangeEmail = (value:string) => {
    if (error) {
      onTyping();
    }
    setEmailState(value);
  }

  const onChecked = (value:string) => {
    if (listsState && listsState[value]) {
      delete listsState[value];
      setListsState({...listsState});
    } else {
      setListsState(listsState ? {...listsState, [value]: true} : { [value]: true });
    }
  }

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Paper
        component="form" 
        onSubmit={(event) => onConfirmClick(event)}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p:2,
        }}
      >
        <Typography variant="h6" textAlign="center" sx={{mb: 5}}>{title}</Typography>
        <TextField 
          fullWidth
          type="email"
          sx={{ mb: 2}}
          error={!!error}
          helperText={error}
          label={local.email}
          value={emailState}
          onChange={(event) => onChangeEmail(event.target.value)}
        />
        <TextField 
          fullWidth
          type="name"
          sx={{ mb: 2}}
          label={local.name}
          value={nameState}
          onChange={(event) => setNameState(event.target.value)}
        />
        {id && (
          <Typography variant='caption' sx={{ mb: 1}}>{local.lists}</Typography>
        )}
        {id && (
          <List
            sx={{
              height: 230,
              overflow: 'auto',
              border: '1px solid lightgrey',
              borderRadius: '5px',
            }}
            dense
            component="div"
            role="list"
          >
            { recipientlists && Object.entries(recipientlists).map(([key, value]: [string, any]) => (
                <ListItem
                  button
                  key={key}
                  role="listitem"
                  onClick={() => onChecked(key)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={listsState && listsState[key] ? true : false}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={value.name} />
                </ListItem>
              ))
            }
          </List>
        )}
        <Grid container spacing={2} sx={{ mt:1 }}>
          <Grid item xs={12} md={6}>
            <Button fullWidth variant="outlined" color="primary" onClick={() => onClose()}>
              <CancelIcon sx={{ mr:1 }} />
              {local.cancel}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button fullWidth type="submit" variant="contained" color="primary">
              <CheckIcon sx={{ mr:1 }} />
              {local.submit}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
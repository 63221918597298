import app from '../config/Firebase';
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL, deleteObject } from "firebase/storage";

const storage = getStorage(app);

export default class Storage {
  static getDownloadURL(path:string){
    return getDownloadURL(ref(storage, path));
  }
  static uploadBytes(path:string, data:any){
    return uploadBytes(ref(storage, path), data);
  }
  static uploadString(path:string, data:any){
    return uploadString(ref(storage, path), data);
  }
  static deleteObject(path:string){
    return deleteObject(ref(storage,path));
  }
}
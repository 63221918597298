import app from '../config/Firebase';
import { getDatabase, ref, onValue, update, push, remove } from "firebase/database";

const database = getDatabase(app);

export default class Database {
  static onValue(path:string, next:Function){
    onValue(ref(database, path), (snapshot) => next(snapshot.val()) );
  }
  static update(path:string, data:any){
    return update(ref(database, path), data);
  }
  static push(path:string, data:any){
    return push(ref(database, path), data);
  }
  static delete(path:string){
    return remove(ref(database,path));
  }
}
import React, { useState, useEffect, useMemo } from 'react';
import { DataGridPro, GridRowsProp } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';

import Database from '../../services/Database';
import RecipientlistsToolbar from './RecipientlistsToolbar';
import RecipientlistModal from './RecipientlistsModal';
import local from './Recipientlists.local';

export default function Recipients() {
  const [recipients, setRecipients] = useState<any>();
  const [recipientlists, setRecipientlists] = useState<any>();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [edit, setEdit] = useState<string|undefined>();
  const [push, setPush] = useState<boolean>(false);
  
  useEffect(() => {
    Database.onValue(`/mails/recipients`, (data:any) => setRecipients(data) );
    Database.onValue(`/mails/recipientlists`, (data:any) => setRecipientlists(data) );
  }, []);

  useEffect(() => {
    if (recipients && recipientlists) {
      const mappedRows = Object.entries(recipientlists).map(([id, {name}]:[string, any]) => {
        const recipientlist = Object.entries(recipients)
          .filter(([key, item]: [string, any]) => item.lists && item.lists[id])
          .map(([key, item]: [string, any]) => item.email); 
        return {
          id,
          name,
          count: recipientlist.length,
          recipients: recipientlist.join('; '),
        }
      });
      setRows(mappedRows);
    }
  }, [recipients, recipientlists]);

  const editRecipientlist = useMemo(() => {
    if (edit === undefined) return [];
    return Object.entries(recipients)
      .filter(([id, item]: [string, any]) => item.lists && item.lists[edit])
      .map(([id, item]: [string, any]) => id); 
  }, [recipients, edit])

  // const onDelete = (key:string) => {
  //   Database.delete(`/pages/${pageKey}/articles/${key}`);
  // }

  const onChange = (name:string, recipientlist:string[], id?:string) => {
    const previousList = editRecipientlist;
    previousList.forEach((key:string) => {
      if (!recipientlist.includes(key)) {
        Database.delete(`/mails/recipients/${key}/lists/${edit}`);
      }
    });
    recipientlist.forEach((key:string) => {
      if (!previousList.includes(key) && edit) {
        Database.update(`/mails/recipients/${key}/lists`, {[edit]: true});
      }
    });
    Database.update(`/mails/recipientlists/${id}`, { name });
    setEdit(undefined);
  }

  const onPush = (name:string, recipientlist:string[],) => {
    Database.push(`/mails/recipientlists`, { name });
    setPush(false);
  }

  const onClose = () => {
    setPush(false);
    setEdit(undefined);
  }

  return (
    <Paper sx={{ height: '75vh'}}>
      <DataGridPro
        pagination
        slots={{ toolbar: RecipientlistsToolbar }}
        slotProps={{ toolbar: { onPush: () => setPush(true) } }}
        editMode="row"
        rows={rows}
        columns={[
          { field: 'name', type: 'string', headerName: local.name, minWidth: 50, flex: 1 },
          { field: 'count', type: 'number', headerName: local.count, minWidth: 50, flex: 1 },
          { field: 'recipients', type: 'string', headerName: local.recipients, minWidth: 50, flex: 3 }
        ]}
        onRowClick={({ row }) => setEdit(row.id)}
      />
      <RecipientlistModal 
        title={push ? local.push : local.edit}
        id={edit}
        name={edit ? recipientlists[edit].name : undefined}
        open={push || edit !== undefined}
        recipients={recipients}
        recipientlist={editRecipientlist}
        onClose={onClose}
        onConfirm={push ? onPush : onChange}
      />
    </Paper>
  );
}
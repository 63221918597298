import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import EditIcon from '@mui/icons-material/Edit';

import Database from '../../services/Database';
import local from './Board.local';

interface Props {
  edit: boolean;
}

export default function Board({edit}: Props) {
  const location = useLocation();
  const [page, setPage] = useState<{name:string, render:any}|undefined>();
  
  useEffect(() => {
    Database.onValue(`/pages${location.pathname}`, (pageData:any) => setPage(pageData) );
  }, [location]);

  const onChange = (key:string, field:string, value:any) => {
    Database.update(`/pages${location.pathname}/render/${key}`, {[field]: value});
  }

  const onRenderCardPush = () => {
    Database.push(`/pages${location.pathname}/render`, {title: 'Neue Karte'});
  }

  const onRenderCardDelete = (key:string) => {
    Database.delete(`/pages${location.pathname}/render/${key}`);
  }

  const renderPage = () => {
    return (
      <Container>
        {page && page.name && (<Typography variant="h1" fontWeight={900} color="primary">{page.name}</Typography>)}
        <Grid container spacing={5}>
          {page && page.render && Object.keys(page.render).map((key) => (
            <Grid item key={key} xs={12} md={6} lg={4}>
              <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardMedia component="img" sx={{width: '100%', maxHeight: '200px'}} image="https://source.unsplash.com/random" alt="random"/>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5">{page.render[key].title}</Typography>
                  {page.render[key].caption && (<Divider sx={{ mb: 1}} />)}
                  <Typography gutterBottom variant="caption">{page.render[key].caption}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    )
  }
  
  return edit ? (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        {renderPage()}
      </Grid> 
      <Grid item xs={12} lg={4}>
        <Paper sx={{width: '100%', p: 1, mt: 1, mr: 1, mb: 5}}>
          <Typography variant="h6" sx={{mb: 1}}>
            <EditIcon sx={{mr: 2}}/>
            {local.edit}
          </Typography>
          {page && page.render && Object.keys(page.render).map((key) => (
            <Box key={key}>
              <Divider sx={{ mt: 2, mb: 1}}/>
              <TextField 
                fullWidth
                size="small"
                sx={{mt: 1}}
                label={local.renderCardTitle} 
                value={page.render[key].title} 
                onChange={(event) => onChange(key, 'title', event.target.value)}
              />
              <TextField
                fullWidth
                multiline
                size="small"
                sx={{mt: 2}}
                label={local.renderCardCaption} 
                value={page.render[key].caption} 
                onChange={(event) => onChange(key, 'caption', event.target.value)}
              />
              <Button fullWidth sx={{mt: 1}} onClick={() => onRenderCardDelete(key)}>{local.renderCardDelete}</Button>
            </Box>
          ))}
          <Divider sx={{ mt: 2, mb: 1}}/>
          <Button fullWidth variant="contained" onClick={onRenderCardPush}>{local.renderCardPush}</Button>
        </Paper>
      </Grid> 
    </Grid>
  ) : (
    renderPage()
  );
}
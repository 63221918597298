const de = {
  email: 'Email',
  name: 'Name',
  status: 'Status',
  lists: 'Listen',
  active: 'Aktiv',
  inactive: 'Abgemeldet',
  error: 'Email konnte nicht zugestellt werden!',
  edit: 'Empfänger bearbeiten',
  emailInUse: 'Email existiert bereits!',
  push: 'Neuer Empfänger',
}
export default de;
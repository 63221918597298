const de = {
  signIn: 'Anmelden',
  email: 'Email',
  password: 'Password',
  remember: 'Email merken?',
  passwordForgotten: 'Passwort vergessen?',
  401: 'Sie haben ein falsches Passwort eingegeben. Bitte prüfen Sie Ihre Eingabe!',
  404: (payload) => `Der Benutzer '${payload.email}' ist nicht registriert. Bitte prüfen Sie Ihre Eingabe!`,
  500: 'Ein unbekannter Fehler ist aufgetretten! Bitte versuchen Sie es in kürze erneut!',
}
export default de;
import React from 'react';

interface Props {
  value: any;
}

export default function DeltaViewer({value}: Props) {

  return (
    <div  dangerouslySetInnerHTML={{__html: value}} />
  )
}
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

import local from './RecipientlistsModal.local';

interface Props {
  title:string;
  id?:string;
  name?:string;
  open:boolean;
  recipients:any,
  recipientlist:string[],
  onClose: () => void;
  onConfirm: (name:string, recipientlist:string[], id?:string) => void;
}

export default function RecipientModal({
  id,
  title,
  name,
  open,
  recipients,
  recipientlist,
  onConfirm,
  onClose,
}: Props) {
  const [nameState, setNameState] = useState<string>('');
  const [recipientlistState, setRecipientlistState] = useState<string[]>([]);
  const [leftChecked, setLeftChecked] = useState<string[]>([]);
  const [rightChecked, setRightChecked] = useState<string[]>([]);
  
  useEffect(() => setNameState(name || ''), [name]);
  useEffect(() => setRecipientlistState(recipientlist || []), [recipientlist]);
  
  const onConfirmClick = (event:any) => {
    event.preventDefault();
    onConfirm(nameState, recipientlistState, id);
  }

  const onChangeName = (value:string) => {
    setNameState(value);
  }

  const onChecked = (key:string, list: string[], setList: any) => {
    const index = list.indexOf(key);
    if (index >= 0) {
      setList(list.filter((item:string) => item !== key));
    } else {
      setList([...list, key]);
    }
  }
  const onConfirmLeft = () => {
    setRecipientlistState(recipientlistState.filter((item:string) => !leftChecked.includes(item)))
    setLeftChecked([]);
  }
  const onConfirmRight = () => {
    setRecipientlistState([...recipientlistState, ...rightChecked]);
    setRightChecked([]);
  }

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Paper
        component="form" 
        onSubmit={(event) => onConfirmClick(event)}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p:2,
        }}
        >
        <Typography variant="h6" textAlign="center" sx={{mb: 5}}>{title}</Typography>
        <TextField 
          fullWidth
          label={local.name}
          value={nameState}
          onChange={(event) => onChangeName(event.target.value)}
        />
        <Grid container spacing={2} sx={{ mt:1 }}>
          { id && (
            <Grid item xs={12} md={5}>
              <Typography>{local.recipients}</Typography>
              <List
                sx={{
                  height: 230,
                  overflow: 'auto',
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                }}
                dense
                component="div"
                role="list"
              >
                {recipients && Object.entries(recipients)
                  .filter(([key, value]: [string, any]) => recipientlistState.includes(key))
                  .map(([key, value]: [string, any]) => (
                    <ListItem
                      button
                      key={key}
                      role="listitem"
                      onClick={() => onChecked(key, leftChecked, setLeftChecked)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={leftChecked.includes(key)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={value.email} />
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
          )}
          { id && (
            <Grid container item xs={12} md={2} direction="column" alignItems={"center"} sx={{mt:10}}>
              <Grid item>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={onConfirmRight}
                  disabled={rightChecked.length === 0}
                  >
                  &lt;
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={onConfirmLeft}
                  disabled={leftChecked.length === 0}
                  >
                  &gt;
                </Button>
              </Grid>
            </Grid>
          )}
          {id && (
            <Grid item xs={12} md={5}>
              <Typography>{local.add}</Typography>
              <List
                sx={{
                  height: 230,
                  overflow: 'auto',
                  border: '1px solid lightgrey',
                  borderRadius: '5px',
                }}
                dense
                component="div"
                role="list"
              >
                {recipients && Object.entries(recipients)
                  .filter(([key, value]: [string, any]) => !recipientlistState.includes(key))
                  .map(([key, value]: [string, any]) => (
                    <ListItem
                      key={key}
                      button
                      role="listitem"
                      onClick={() => onChecked(key, rightChecked, setRightChecked)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={rightChecked.includes(key)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={value.email} />
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
          )}
            <Grid item xs={12} md={6}>
              <Button fullWidth variant="outlined" color="primary" onClick={() => onClose()}>
                <CancelIcon sx={{ mr:1 }} />
                {local.cancel}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button fullWidth type="submit" variant="contained" color="primary">
                <CheckIcon sx={{ mr:1 }} />
                {local.submit}
              </Button>
            </Grid>
          </Grid>
      </Paper>
    </Modal>
  );
}
import React, { useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { ImageResize } from 'quill-image-resize-module-ts';
import { ImageActions } from '@xeger/quill-image-actions';
import { ImageFormats } from '@xeger/quill-image-formats';

import EditorToolbar from './EditorToolbar';

Quill.register('modules/imageActions', ImageActions);
Quill.register('modules/imageFormats', ImageFormats);
Quill.register('modules/imageResize', ImageResize);

const formats = [
  'background', 'bold', 'color', 'font', 'code', 'italic', 'link', 'size', 'strike', 'script', 'underline', 
  'blockquote', 'header', 'indent', 'list', 'align', 'direction', 'code-block', 
  'formula', 'image', 'video', 'float', 'width',
];

interface Props {
  initialValue: any;
  uploadImage: (file: any) => Promise<string>;
  onChange: (newValue: any) => void; 
}

export default function Editor({initialValue, uploadImage, onChange}: Props) {
  const [quill, setQuill] = useState<any>();
  const [value, setValue] = useState<string>(initialValue);
  const [updateTimeout, setUpdateTimeout] = useState<any>();

  const onTextChange = (newValue: string) => {
    clearTimeout(updateTimeout);
    setUpdateTimeout(setTimeout(() => onChange(newValue), 1000));
    setValue(newValue);
  }

  return (
    <div>
      <EditorToolbar quill={quill} uploadImage={uploadImage} />
      <ReactQuill
        value={value}
        ref={(ref) => setQuill(ref?.getEditor())}
        onChange={(newValue: string) => { onTextChange(newValue) }}
        style={{width: '100%'}}
        formats={formats}
        modules={{
          toolbar: {
            container: "#toolbar",
          },
          clipboard: {
            matchVisual: false
          },
          imageActions: {},
          imageFormats: {},
          imageResize: {
            parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
          }
        }}
      />
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { DataGridPro, GridRowsProp } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';

import Database from '../../services/Database';
import RecipientsToolbar from './RecipientsToolbar';
import RecipientModal from './RecipientModal';
import local from './Recipients.local';

export default function Recipients() {
  const [recipients, setRecipients] = useState<any>();
  const [recipientlists, setRecipientlists] = useState<any>();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [edit, setEdit] = useState<string|undefined>();
  const [push, setPush] = useState<boolean>(false);
  const [error, setError] = useState<string|undefined>();
  
  useEffect(() => {
    Database.onValue(`/mails/recipients`, (data:any) => setRecipients(data) );
    Database.onValue(`/mails/recipientlists`, (data:any) => setRecipientlists(data) );
  }, []);

  useEffect(() => {
    if (recipients) {
      const mappedRows = Object.entries(recipients).map(([id, {email, name, lists, inactive, error}]:[string, any]) => {
        const status = inactive ? local.inactive : local.active;
        const recipientslists = recipientlists ? Object.entries(recipientlists)
          .filter(([id, value]: [string, any]) => lists && Object.keys(lists).includes(id))
          .map(([id, value]: [string, any]) => value.name) : [];
        return {
          id,
          email,
          name,
          status: error ? local.error : status,
          lists: recipientslists.join('; '),
        }
      });
      setRows(mappedRows);
    }
  }, [recipients, recipientlists]);

  // const onDelete = (key:string) => {
  //   Database.delete(`/pages/${pageKey}/articles/${key}`);
  // }

  const onChange = (email:string, name:string, lists:any, id?:string) => {
    if (rows.find((item) => item.email === email && item.id !== id)) {
      setError(local.emailInUse);
    } else {
      Database.update(`/mails/recipients/${id}`, {email, name, lists});
      setEdit(undefined);
    }
  }

  const onPush = (email:string, name:string) => {
    if (rows.find((item) => item.email === email)) {
      setError(local.emailInUse);
    } else {
      Database.push(`/mails/recipients`, { email, name });
      setPush(false);
    }
  }

  const onClose = () => {
    setPush(false);
    setEdit(undefined);
  }

  return (
    <Paper sx={{ height: '75vh'}}>
      <DataGridPro
        pagination
        slots={{ toolbar: RecipientsToolbar }}
        slotProps={{ toolbar: { onPush: () => setPush(true) } }}
        editMode="row"
        rows={rows}
        columns={[
          { field: 'email', type: 'string', headerName: local.email, minWidth: 50, flex: 2 },
          { field: 'name', type: 'string', headerName: local.name, minWidth: 50, flex: 2 },
          { field: 'status', type: 'string', headerName: local.status, minWidth: 50, flex: 1 },
          { field: 'lists', type: 'string', headerName: local.lists, minWidth: 50, flex: 3 }
        ]}
        onRowClick={({ row }) => setEdit(row.id)}
      />
      <RecipientModal 
        title={push ? local.push : local.edit}
        id={edit}
        email={edit ? recipients[edit].email : undefined}
        name={edit ? recipients[edit].name : undefined}
        lists={edit ? recipients[edit].lists : undefined}
        open={push || edit !== undefined}
        error={error}
        recipientlists={recipientlists}
        onClose={onClose}
        onConfirm={push ? onPush : onChange}
        onTyping={() => setError(undefined)}
      />
    </Paper>
  );
}
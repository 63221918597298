import React from 'react';
import { SketchPicker } from 'react-color';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Database from '../services/Database';
import Storage from '../services/Storage';
import FileUpload from '../components/FileUpload';
import local from './Settings.local';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

interface Props {
  title?: string;
  logo?: string;
  theme: any;
  pages: any;
}

export default function Settings({title, logo, theme, pages}: Props) {
  
  const onColorChange = (palette:string, color:string) => {
    Database.update(`/settings/theme/palette/${palette}`, {main: color});
  }
  
  const onPagesPush = () => {
    Database.push('/pages', {
      isActive: false,
      name: 'Neue Seite',
      navigate: true,
      order: pages ? Object.keys(pages).length + 1 : 1,
      type: 'article',
    })
  }

  const onChange = (key:string, field:string, value:any) => {
    Database.update(key, {[field]: value});
  }

  const onPageChange = (key:string, field:string, value:any) => onChange(`/pages/${key}`, field, value);

  const onPagesDelete = (key:string) => {
    Database.delete(`/pages/${key}`);
  }

  const onFileChange = async (file:any) => {
    await Storage.uploadBytes(`/settings/logo`, file);
    const url = await Storage.getDownloadURL(`/settings/logo`);
    Database.update(`/settings`, { logo: url });
  }

  return (
    <Container sx={{pb: 5}}>
      <Typography variant="h2" color="primary" sx={{mt:2}}>{local.settings}</Typography>
      <Typography variant="h3" color="primary" sx={{mt:2}}>{local.title}</Typography>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={logo ? 5 : 6}>
          <TextField 
            fullWidth
            label={local.title}
            value={title}
            onChange={(event) => onChange('settings', 'title', event.target.value)}
            sx={{mt: 1}}
          />
        </Grid>
        { logo && (
          <Grid item xs={12} md={2} textAlign="center" >
            <img src={logo} alt="logo" height="150px" width="150px"/>
          </Grid>
        )}
        <Grid item xs={12} md={logo ? 5 : 6}>
          <FileUpload accept='image/*' onChange={onFileChange} />
        </Grid>
      </Grid>
      <Typography variant="h3" color="primary" sx={{mt:2}}>{local.theme}</Typography>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <Grid container spacing={2} sx={{mb: 5}}>
        <Grid item xs={12} md={3}>
          <Paper sx={{p: 2, height: '305px'}}>
            <Typography sx={{mt: 1}} color="primary" variant="h4">{local.themeParlettePrimary}</Typography>
            <Typography sx={{mt: 2}}>{local.themeParlettePrimaryDetails}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <SketchPicker
            color={ theme && theme.palette && theme.palette.primary && theme.palette.primary.main ? theme.palette.primary.main : undefined}
            onChangeComplete={ (color) => onColorChange('primary', color.hex) }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper sx={{p: 2, height: '305px'}}>
            <Typography color="secondary" variant="h4">{local.themeParletteSecondary}</Typography>
            <Typography sx={{mt: 2}}>{local.themeParletteSecondaryDetails}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <SketchPicker 
            color={ theme && theme.palette && theme.palette.secondary && theme.palette.secondary.main ? theme.palette.secondary.main : undefined}
            onChangeComplete={ (color) => onColorChange('secondary', color.hex) }
          />
        </Grid>
      </Grid>
      <Box display="flex" sx={{mb:1}}>
        <Typography variant="h3" color="primary">{local.pages}</Typography>
        <Box flexGrow={1} />
        <Button variant="outlined" onClick={onPagesPush}>
          <AddIcon sx={{ mr: 1 }} />
          <Typography>{local.pagesPush}</Typography>
        </Button>

      </Box>
        {pages && Object.keys(pages)
          .sort((a,b) => (pages[a].sort || 1) - (pages[b].sort ||1)).map((key:string) => (
            <Accordion key={key}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography color={pages[key].color}>{`${pages[key].sort || 1}. ${pages[key].name}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField 
                  fullWidth
                  size='small'
                  label={local.pagesName}
                  value={pages[key].name}
                  onChange={(event) => onPageChange(key, 'name', event.target.value)}
                  sx={{mt: 1}}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="pagesTypes-label">{local.pagesTypes}</InputLabel>
                  <Select
                    size='small'
                    labelId="pagesTypes-label"
                    id="pagesTypes"
                    label={local.pagesTypes}
                    value={pages[key].type}
                    onChange={(event) => onPageChange(key, 'type', event.target.value)}
                  >
                    <MenuItem value={'article'}>{local.pagesTypesArticle}</MenuItem>
                    <MenuItem value={'articles'}>{local.pagesTypesArticles}</MenuItem>
                    <MenuItem value={'newsletter'}>{local.pagesTypesNewsletter}</MenuItem>
                    <MenuItem value={'gallery'}>{local.pagesTypesGallery}</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="pagesColor-label">{local.pagesColor}</InputLabel>
                  <Select
                    size='small'
                    labelId="pagesColor-label"
                    id="pagesColor"
                    label={local.pagesColor}
                    value={pages[key].color || 'black'}
                    onChange={(event) => onPageChange(key, 'color', event.target.value)}
                  >
                    <MenuItem value={'black'}>{local.pagesColorBlack}</MenuItem>
                    <MenuItem value={'primary'}>{local.pagesColorPrimary}</MenuItem>
                    <MenuItem value={'secondary'}>{local.pagesColorSecondary}</MenuItem>
                    <MenuItem value={'error'}>{local.pagesColorError}</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel sx={{ p:1 }} label={local.pagesIsActive} control={
                  <Switch checked={pages[key].isActive} onChange={(event) => onPageChange(key, 'isActive', event.target.checked)} />
                } />
                <FormControlLabel sx={{ p:1 }} label={local.pagesNavigate} control={
                  <Switch checked={pages[key].navigate} onChange={(event) => onPageChange(key, 'navigate', event.target.checked)} />
                } />
                <TextField 
                  fullWidth
                  type='number'
                  size='small'
                  label={local.sort}
                  value={pages[key].sort || 1}
                  onChange={(event) => onPageChange(key, 'sort', event.target.value)}
                  sx={{mt: 1}}
                />
                <Divider sx={{ mt: 2}} />
                <Button fullWidth variant="contained" sx={{mt: 2}} onClick={() => onPagesDelete(key)}>{local.pagesDelete}</Button>
              </AccordionDetails>
            </Accordion>
        ))}
    </Container>
  );
}
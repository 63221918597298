import React from 'react';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import AddIcon from '@mui/icons-material/Add';

import local from './RecipientsToolbar.local';

interface Props {
  onPush: () => void;
}

export default function RecipientsToolbar({onPush}: Props) {

  return (
    <GridToolbarContainer>
      <Button variant="text" color="primary" size="small" onClick={onPush}>
        <AddIcon />
        {local.push}
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      <Hidden mdDown>
        <GridToolbarColumnsButton  />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
      </Hidden>
    </GridToolbarContainer>
  );
}

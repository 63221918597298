const de = {
  settings: 'Einstellungen',
  title: 'Titel',
  theme: 'Design',
  themeParlettePrimary: 'Primärfarbe',
  themeParlettePrimaryDetails: `Die Primärfarbe wird am häufigsten verwendet und in der Regel für die Kopfleiste, wichtige Buttons und Überschriften verwendet und hat einen starken visuellen Einfluss auf die Benutzeroberfläche`,
  themeParletteSecondary: 'Sekundärfarbe',
  themeParletteSecondaryDetails: 'Die Sekundärfarbe wird verwendet um bestimmte Bereiche der Benutzeroberfläche hervorzuheben und zu betonen, wie zum Beispiel Navigations-Buttons, kleinere Überschiften etc.',
  pages: 'Seiten',
  pagesPush: 'Seite hinzufügen',
  pagesName: 'Seiten Name',
  pagesTypes: 'Seitentyp',
  pagesTypesArticle: 'Artikel',
  pagesTypesArticles: 'Artikelsammlung',
  pagesTypesNewsletter: 'Newsletter',
  pagesTypesBoard: 'Liste',
  pagesTypesGallery: 'Galerie',
  pagesIsActive: 'Seite aktiv?',
  pagesNavigate: 'Seite in Navigation anzeigen?',
  pagesDelete: 'Seite löschen',
  pagesColor: 'Text-Farbe',
  pagesColorBlack: 'Schwarz',
  pagesColorPrimary: 'Hauptfarbe',
  pagesColorSecondary: 'Sekundärfarbe',
  pagesColorWarning: 'Orange',
  pagesColorError: 'Rot',
  sort: 'Sortierung',
}
export default de;
const de = {
  name: 'Titel',
  caption: 'Kurzbeschreibung',
  delete: 'Artikel löschen',
  deleteConfirm: 'Artikel löschen!',
  deleteAbort: 'Abbrechen',
  type: 'Typ',
  typeCard: 'Karte',
  typeArticle: 'Artikel',
  typeLink: 'Link',
  sort: 'Sortierung',
  linkHelper: 'Link muss mit http:// oder https:// starten!',
  confirmDelete: (name) => `Möchten Sie den Artikel '${name}' wirklich löschen?`
}
export default de;
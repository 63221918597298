import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';

import MenuIcon from '@mui/icons-material/Menu';
// import LoginIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

import Article from './pages/article/Article';
import Articles from './pages/articles/Articles';
import Newsletter from './pages/newsletter/Newsletter';
import Board from './pages/board/Board';
import Gallery from './pages/gallery/Gallery';
import Login from './pages/login/Login';
import Settings from './settings/Settings';
import Mails from './mails/Mails';

const drawerWidth = 500;

interface Props {
  edit: boolean;
  logo: string|undefined;
  mailEnabled: boolean,
  onLogin: (userAuth: any) => void;
  onLogout: () => void;
  onNavigate: (path: string) => void;
  pages: any;
  setEdit: (edit: boolean) => void;
  theme: any;
  title: string|undefined;
  user: any;
}

export default function AppLayout({
  edit, 
  logo,
  mailEnabled,
  onLogin,
  onLogout,
  onNavigate,
  pages, 
  setEdit,
  theme,
  title,
  user,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  
  const elementMap = {
    article: <Article edit={edit}/>,
    articles: <Articles edit={edit}/>,
    newsletter: <Newsletter edit={edit}/>,
    board: <Board edit={edit}/>,
    gallery: <Gallery edit={edit}/>,
  }

  const handleMenuItemClick = (key:string) => {
    setOpen(false);
    onNavigate(key);
  }
  
  return (
    <Container disableGutters maxWidth={false}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton sx={{ mr:1 }} onClick={() => setOpen(!open)}><MenuIcon/></IconButton>
          {logo && (<IconButton sx={{ mr:1}} onClick={() => onNavigate('/')}><img src={logo} alt="logo" height="50px" width="50px" /></IconButton>)}
          <Hidden mdDown>
            {title && (<Typography variant="h4" color="secondary" sx={{cursor: 'pointer', color: 'white', fontWeight: '900' }} onClick={() => onNavigate('/')}>{title}</Typography>)}
          </Hidden>
          <Box sx={{flexGrow:1}}  />
          {/* {!user && <IconButton onClick={() => onNavigate('/login')}><LoginIcon/></IconButton>} */}
          {user && <IconButton onClick={() => setEdit(!edit)}>{edit ? <SaveIcon /> : <EditIcon/>}</IconButton>}
          {user && mailEnabled &&  <IconButton onClick={() => onNavigate('/mails')}><MailIcon/></IconButton>}
          {user && <IconButton onClick={() => onNavigate('/settings')}><SettingsIcon/></IconButton>}
          {user && <IconButton onClick={() => onLogout()}><LogoutIcon/></IconButton>}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        variant="temporary"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{ keepMounted: true }}
        sx={{
          width: drawerWidth,
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth } 
        }}
      >
        <Box sx={{mt:10}}>
          { logo && (
            <Box textAlign="center">
              <img src={logo} width="150px" alt="logo" />
            </Box>
          )}
          <MenuList>
            <MenuItem onClick={() => handleMenuItemClick('/')}><Typography>{title}</Typography></MenuItem>
            { pages && Object.keys(pages)
              .filter((key:string) => pages[key].isActive && pages[key].navigate)
              .sort((a,b) => (pages[a].sort || 1) - (pages[b].sort || 1))
              .map((key:string) => (
                <MenuItem 
                  key={key} 
                  onClick={() => handleMenuItemClick(key)}
                >
                  <Typography color={pages[key].color}>{pages[key].name}</Typography>
                </MenuItem>
              )) 
            }
          </MenuList>
        </Box>
      </Drawer>
      <Container disableGutters maxWidth={false} sx={{mt:10, mb: 10, ml: 1, mr: 1}}>
        <Routes>
          <Route path="/" element={<Article edit={edit} />} />
          { pages && Object.keys(pages).filter((key:string) => pages[key].isActive).map((key:string) => (
            <Route key={key} path={`${key}/*`} element={elementMap[pages[key].type as keyof typeof elementMap]} />
            )) }
          {!user && (<Route path="/login" element={<Login onLogin={onLogin} />} />)}
          {user && (<Route path="/settings" element={<Settings title={title} logo={logo} theme={theme} pages={pages}/>} />)}
          {user && (<Route path="/mails/*" element={<Mails />} />)}
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Container>
    </Container>
  );
}


import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-quill/dist/quill.snow.css';
import './easyWeb.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

LicenseInfo.setLicenseKey('f53e8a2438001b8ce3bfdd5f9f8952d1Tz01NDc3NyxFPTE3MDA2NzQ1NTY1NDIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CssBaseline />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

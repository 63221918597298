import React from 'react';
import ImageIcon from '@mui/icons-material/Image';

interface Props {
  quill: any
  uploadImage: (file: any) => Promise<string>,
}

export default function EditorToolbar({quill, uploadImage} : Props) {

  const imageHandler = async function () {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      if (quill && input.files && input.files[0]) {
        const url = await uploadImage(input.files[0]);
        const range = quill.getSelection(true);
        quill.insertEmbed(range.index, 'image', url);
        quill.setSelection(range.index + 1);
      }
    };
  }

  return (
    <div id="toolbar">
      <span className="ql-formats">
        <select className="ql-size" defaultValue="medium">
          <option value="small">Klein</option>
          <option value="medium">Normal</option>
          <option value="large">Groß</option>
        </select>
        <select className="ql-header" defaultValue="7">
          <option value="1">Überschrift 1</option>
          <option value="2">Überschrift 2</option>
          <option value="3">Überschrift 3</option>
          <option value="4">Überschrift 4</option>
          <option value="5">Überschrift 5</option>
          <option value="6">Überschrift 6</option>
          <option value="7">Text</option>
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
      </span>
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <button className="ql-indent" value="-1" />
        <button className="ql-indent" value="+1" />
      </span>
      <span className="ql-formats">
        <button className="ql-script" value="super" />
        <button className="ql-script" value="sub" />
        <button className="ql-blockquote" />
      </span>
      <span className="ql-formats">
        <select className="ql-align" />
        <select className="ql-color" />
        <select className="ql-background" />
      </span>
      <span className="ql-formats">
        <button className="ql-link" />
        <button onClick={imageHandler}><ImageIcon/></button>
        <button className="ql-video" />
      </span>
      <span className="ql-formats">
        <button className="ql-code-block" />
        <button className="ql-clean" />
      </span>
    </div>
  );
}
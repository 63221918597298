import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ImageGallery from 'react-image-gallery';

import Database from '../../services/Database';
import Storage from '../../services/Storage';
import local from './Gallery.local';
import './ImageGallery.css';

interface Props {
  edit: boolean;
}

export default function Gallery({edit}: Props) {
  const [page, setPage] = useState<{name:string, images:any}|undefined>();
  const location = useLocation();
  const pageKey = location.pathname.split('/')[1];
  
  useEffect(() => {
    Database.onValue(`/pages/${pageKey}`, (pageData:any) => setPage(pageData) );
  }, [pageKey]);

  const onChangeImage = (key:string, field:string, value:string) => {
    Database.update(`/pages/${pageKey}/images/${key}`, {field: value});
  }

  const onDelete = (key:string) => {
    Database.delete(`/pages/${pageKey}/images/${key}`);
  }

  const onImagePush = async (event:any) => {
    const file = event.target.files[0];
    const { key } = await Database.push(`/pages/${pageKey}/images`, {name: file.name}); 
    await Storage.uploadBytes(`/pages/${pageKey}/images/${key}/original`, file);
    const url = await Storage.getDownloadURL(`/pages/${pageKey}/images/${key}/original`);
    Database.update(`/pages/${pageKey}/images/${key}`, { original: url, thumbnail: url });
  }

  return page ? (
    <Container>
      <Typography variant='h1' color="primary">{page.name}</Typography>
      <Divider sx={{ mb:2 }}/>
      {edit ? (
        <Grid container spacing={5}>
          {page.images && Object.keys(page.images).map((key) => (
            <Grid item key={key} xs={12} md={6} lg={4}>
              <Card>
                <CardMedia component="img" sx={{maxHeight: '200px'}} image={page.images[key].original} alt="random" />
                <CardContent>
                  <TextField 
                    fullWidth
                    size="small"
                    sx={{mt: 1}}
                    label={local.name} 
                    value={page.images[key].name} 
                    onChange={(event) => onChangeImage(key, 'name', event.target.value)}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12} md={6} lg={4}>
            <Button fullWidth variant="outlined" component="label">
              <Typography>+</Typography>
              <input hidden type="file" accept="image/*" onChange={onImagePush}/>
            </Button>
          </Grid>
        </Grid>
      ) : (
        page.images && (
          <Box sx={{maxHeight:'500px'}}>
            <ImageGallery items={Object.values(page.images)} additionalClass="image-gallery" showIndex  />
          </Box>
        )
      )}
    </Container>
  ) : (
    <Backdrop sx={{ color: '#fff' }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
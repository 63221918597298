import app from '../config/Firebase';
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  // sendPasswordResetEmail, confirmPasswordReset, 
  signOut } from "firebase/auth";

const auth = getAuth(app);

export default class Authentication {
  static signIn(email:string, password:string, next:Function){
    signInWithEmailAndPassword(auth, email, password)
      .then(({user}) => next({user}))
      .catch((error) => next({error}));
  }
  static signOut(next:Function){
    signOut(auth)
    .then(() => next())
    .catch((error) => next({error}));
  }
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, Theme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import { deDE as muiMaterialDeDE } from '@mui/material/locale';
import { deDE as muiXDataGridDeDE } from '@mui/x-data-grid';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Database from './services/Database';
import Authentication from './services/Authentication';

import AppLayout from './AppLayout';


const styleElementId = 'dynamic-header-color';
export default function App() {
  const [theme, setTheme] = useState<Theme | undefined>();
  const [user, setUser] = useState();
  const [title, setTitle] = useState<string | undefined>();
  const [logo, setLogo] = useState<string | undefined>();
  const [mailEnabled, setMailEnabled] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [pages, setPages] = useState<any | undefined>();
  const navigate = useNavigate();
  
  useEffect( () => {
    Database.onValue('/settings/theme', (themeData:ThemeOptions) => setTheme(createTheme(themeData, muiMaterialDeDE, muiXDataGridDeDE)));
    Database.onValue('/settings/title', (titleData:string) => setTitle(titleData));
    Database.onValue('/settings/logo', (logoData:string) => setLogo(logoData));
    Database.onValue('/settings/mailenabled', (mailenabledSetting:boolean) => setMailEnabled(mailenabledSetting));
    Database.onValue('/pages', (pagesData:string) => setPages(pagesData));
  }, []);

  useEffect(() => {
    let styleElement = document.getElementById(styleElementId);
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = styleElementId;
      document.head.appendChild(styleElement);
    }
    styleElement.innerHTML = `
      h1 { color: ${theme?.palette.primary.main}; }
      h2 { color: ${theme?.palette.primary.main}; }
      h3 { color: ${theme?.palette.primary.main}; }
      h4 { color: ${theme?.palette.secondary.main}; }
      h5 { color: ${theme?.palette.secondary.main}; }
      h6 { color: ${theme?.palette.secondary.main}; }
    `;
    return () => {
      const styleElementToRemove = document.getElementById(styleElementId);
      if (styleElementToRemove) {
        document.head.removeChild(styleElementToRemove);
      }
    };
  }, [theme]);

  
  const onNavigate = (path:string) => {
    navigate(path);
  }

  const onLogin = (userAuth:any) => {
    onNavigate('/');
    setUser(userAuth);
  }
  
  const onLogout = () => {
    Authentication.signOut(() => {
      onNavigate('/');
      setUser(undefined);
    });
  }

  return theme ? (
    <ThemeProvider theme={theme}>
      <AppLayout 
        edit={edit}
        logo={logo}
        mailEnabled={mailEnabled}
        onLogin={onLogin}
        onLogout={onLogout}
        onNavigate={onNavigate}
        pages={pages}
        setEdit={setEdit}
        theme={theme}
        title={title}
        user={user}
      />
    </ThemeProvider>
  ) : (
    <Backdrop sx={{ color: '#fff' }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}


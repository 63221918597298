import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';

import local from './ArticleCard.local';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  caption: string|undefined;
  edit: boolean;
  id: string;
  name: string;
  image: string|undefined;
  type: string;
  link: string|undefined;
  sort: number;
  onClick: (id:string) => void;
  onChange: (id:string, field:string, value:string) => void;
  onDelete: (id:string) => void;
  onImagePush: (id:string, event:any) => void;
}

export default function Articles({
  caption,
  edit,
  id,
  name,
  image,
  type,
  link,
  sort,
  onClick,
  onChange,
  onDelete,
  onImagePush,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  
  const onDeleteClick = () => setOpen(true);
  const onDeleteConfirmClick = () => {
    setOpen(false);
    onDelete(id);
  }
  const onDeleteAbortClick = () => setOpen(false);

  if (edit) {
    return (
      <Card>
        <CardMedia component="img" sx={{width: '100%', maxHeight: '200px'}} image={image} alt="" />
        <CardContent>
          <Button fullWidth variant="outlined" component="label" sx={{mb: 1}}>
              <Typography>Bild hochladen</Typography>
              <input hidden type="file" accept="image/*" onChange={(event) => onImagePush(id, event)}/>
            </Button>
          <TextField 
            fullWidth
            size="small"
            sx={{mt: 1}}
            label={local.name} 
            value={name} 
            onChange={(event) => onChange(id, 'name', event.target.value)}
          />
          <TextField
            fullWidth
            multiline
            size="small"
            sx={{mt: 2}}
            label={local.caption} 
            value={caption} 
            onChange={(event) => onChange(id, 'caption', event.target.value)}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="type-label">{local.type}</InputLabel>
            <Select
              size='small'
              labelId="type-label"
              id="type"
              label={local.type}
              value={type}
              onChange={(event) => onChange(id, 'type', event.target.value)}
            >
              <MenuItem value={'card'}>{local.typeCard}</MenuItem>
              <MenuItem value={'article'}>{local.typeArticle}</MenuItem>
              <MenuItem value={'link'}>{local.typeLink}</MenuItem>
            </Select>
          </FormControl>
          { type === 'link' && (

            <TextField
              fullWidth
              size="small"
              sx={{mt: 2}}
              label={local.typeLink} 
              value={link}
              helperText={!(link?.startsWith('http://') || link?.startsWith('https://')) ? local.linkHelper : ''}
              error={!(link?.startsWith('http://') || link?.startsWith('https://'))}
              onChange={(event) => onChange(id, 'link', event.target.value)}
            />
          )}
          <TextField
            fullWidth
            type="number"
            size="small"
            sx={{mt: 2}}
            label={local.sort} 
            value={sort} 
            onChange={(event) => onChange(id, 'sort', event.target.value)}
          />
          <Button fullWidth sx={{ m: 1, mt:3 }} onClick={onDeleteClick}>{local.delete}</Button>
          <Modal open={open} onClose={() => setOpen(false)}>
            <Paper sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              p:2,
            }}>
              <Typography variant="h6" sx={{ mb:3 }}>{local.confirmDelete(name)}</Typography>
              <Button sx={{ width:'50%' }} onClick={onDeleteConfirmClick}>{local.deleteConfirm}</Button>
              <Button sx={{ width:'50%' }} onClick={onDeleteAbortClick}>{local.deleteAbort}</Button>
            </Paper>
          </Modal>
        </CardContent>
      </Card>
    );
  }

  if (type === 'article' || type === 'link') {
    return (
      <Card>
        <CardActionArea onClick={() => onClick(id)}>
          <CardMedia component="img" sx={{width: '100%'}} image={image} alt="" />
          <CardContent>
            <Typography gutterBottom variant="h5">{name}</Typography>
            {caption && (<Divider sx={{ mb: 1}} />)}
            <Typography gutterBottom paragraph display="block" variant="caption">{caption}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card>
      <CardMedia component="img" sx={{width: '100%' }} image={image} alt="" />
      <CardContent>
        <Typography gutterBottom variant="h5">{name}</Typography>
        {caption && (<Divider sx={{ mb: 1}} />)}
        <Typography gutterBottom variant="caption">{caption}</Typography>
      </CardContent>
    </Card>
  );
}
import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Editor from '../../components/Editor';
import Viewer from '../../components/Viewer';
import Database from '../../services/Database';
import Storage from '../../services/Storage';

interface Props {
  edit: boolean;
}

export default function Article({edit}: Props) {
  const [path, setPath] = useState<string|undefined>();
  const [page, setPage] = useState<any|undefined>();
  let location = useLocation();
  const firstKey = location.pathname.split('/')[1];
  const secondKey = location.pathname.split('/')[2];
  
  useEffect(() => {
    const newPath = `${firstKey ? `/pages/${firstKey}` : '/home'}${secondKey ? `/articles/${secondKey}`: ''}`;
    setPath(newPath);
    Database.onValue(newPath, (value:any) => setPage(value) );
  }, [firstKey, secondKey]);

  const onChange = (value: string) => {
    Database.update(`${path}`, {html: value});
  }

  const uploadImage = async (file: any) => {
    await Storage.uploadBytes(`${path}/images/${file.name}`, file);
    return Storage.getDownloadURL(`${path}/images/${file.name}`);
  }

  return page && path ? (
    <Container disableGutters>
      { page.name !== 'home' && (
        <>
          <Typography variant="h1" fontWeight={900} color="primary">{page.name}</Typography>
          <Divider />
        </>
      )}
      { edit ? (
        <Editor initialValue={page.html} uploadImage={uploadImage} onChange={onChange} />
      ) : (
        <Viewer value={page.html} />
      ) }
    </Container>
  ) : (
    <Backdrop sx={{ color: '#fff' }} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}